import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home.js";
import Movie from "./pages/Movie.js";
import About from "./pages/About.js";
import Commercial from "./pages/Commercial.js";
import Contact from "./pages/Contact.js";
import Layout from "./pages/Layout.js";
import NoPage from "./pages/NoPage.js";
import './App.css';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<Home />} />
          <Route path="/fiktion" element={<Movie />} />
          <Route path="/om-phoenix" element={<About />} />
          <Route path="/reklamefilm" element={<Commercial />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/*" element={<NoPage />} />
        </Route>
      </Routes>
    </Router>
  );
}
export default App;