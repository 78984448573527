import React from "react";
import VideoCommercial from '../video/Reklame.mp4';
import ComImgTile from "../components/ComImgTile.js";
import '../App.css';

function Commercial() {
  return (
    <div>
        <div className="movieHeroContainer">
            <video className="commercialHero" src={VideoCommercial} autoPlay loop muted />
        </div>
        <ComImgTile />
    </div>
  );
}

export default Commercial;