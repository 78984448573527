import React from "react";
import VideoFiktion from '../video/Fiktion.mp4';
import MovieImgTile from "../components/MovieImgTile.js";
import '../App.css';

function Movie() {
  return (
    <div>
        <div className="movieHeroContainer">
            <video className="movieHero" src={VideoFiktion} autoPlay loop muted />
        </div>
        <MovieImgTile />
    </div>
  );
}

export default Movie;