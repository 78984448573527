import React from "react";
import TopImageHero from "../components/TopImageHero.js";
import '../App.css';

function Home() {
  return (
    <div>
      <TopImageHero />
    </div>
  );
}

export default Home;