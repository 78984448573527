import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Marc from '../img/Marc.jpg';
import Jesper from '../img/Jesper.jpg';
import Linnea from '../img/Linnea.jpg';
import '../App.css';

function About2() {
  return (
    <div>
      <div className="navBlocker"></div>
        <div className="aboutTopSection" fluid>
          <Container className="text-dark" >
              <Row className="" style={{backgroundColor: "#FFF"}}>
              <h2 className="aboutTopTitle">OM PHOENIX FILM</h2>
              <Col sm="12" lg="6">
                  <div className="aboutTitle">
                  <h2>+45 23 66 05 17</h2>
                  <h2 className="sub">kontakt@phoenix-film.com</h2>
                  </div>
              </Col>
              <Col sm="12" lg="6" style={{height:"100%"}} className="aboutText">
              <p>Phoenix Film Productions er et dansk produktionsselskab, som ligger i hjertet af København. Vi brænder for at fortælle historier som gør indtryk og vi arbejder i flere medier som fiktion, tv og reklamefilm. Vi har et bredt netværk af samarbejdspartnere og sætter kun de bedste folk på de givne opgaver indenfor deres kompetanceområder. Hvis du har en idé eller et koncept, som du gerne vil dele med os, er du velkommen til at kontakte os.</p>
              </Col>
              </Row>
          </Container>
        </div>
        <Container className="aboutBottomSection">
          <Row className=" justify-content-md-center" style={{textAlign:"center"}}>
            <Col sm="12" lg="4">
              <Card className="cardContainer">
                <Card.Img variant="top" src={Marc} />
                <Card.Body>
                  <Card.Title>Marc Peter Harpsøe</Card.Title>
                  <Card.Text>
                    <p>
                      <b>Founder & Head of Fiction</b> <br />
                      <span style={{fontSize:"17px"}}>mph@phoenix-film.com</span><br />
                      <span style={{fontSize:"17px"}}>+45 30 48 09 03</span><br />
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12" lg="4">
              <Card className="cardContainer">
                <Card.Img variant="top" src={Jesper} />
                <Card.Body>
                  <Card.Title>Jesper Alexander Blok</Card.Title>
                  <Card.Text>
                    <p>
                      <b>Co-Founder & Head of Commercials</b> <br />
                      <span style={{fontSize:"17px"}}>Jab@phoenix-film.com</span><br />
                      <span style={{fontSize:"17px"}}>+45 31 35 48 31</span><br />
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12" lg="4">
              <Card className="cardContainer">
                <Card.Img variant="top" src={Linnea} />
                <Card.Body>
                  <Card.Title>Linnea Olivia Belfrage</Card.Title>
                  <Card.Text>
                    <p>
                      <b>&nbsp;&nbsp;&nbsp;Head of Development</b> <br />
                      <span style={{fontSize:"17px"}}>lob@phoenix-film.com</span><br />
                      <span style={{fontSize:"17px"}}>+45 23 66 05 17</span><br /><br />
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
    </div>
  );
}

export default About2;