import topImg from '../img/phoenixTopImage.jpg';
import VideoLogo from '../img/phoenix-logo-hvid.png';
import '../App.css';

function TopImageHero() {
  return (
          <div>
            <img src={topImg} style={{width:"100%", height:"100vh"}} />
            <div className='heroContent'>
              <img src={VideoLogo} className="topImgContent" />
            </div>
          </div>
          );
        }
export default TopImageHero; 