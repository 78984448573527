import React from "react";
import { Navigation } from "../components/Navigation.js";
import Footer from "../components/Footer.js";
import '../App.css';

function Layout() {
  return (
    <div className="" fluid>
      <Navigation />

      <Footer />
    </div>
  );
}

export default Layout;