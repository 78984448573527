import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Com1 from '../img/commercial/Com-1.jpg';
import Com2 from '../img/commercial/Com-2.jpg';
import Com3 from '../img/commercial/Com-3.jpg';
import Com4 from '../img/commercial/Com-4.jpg';
import Com5 from '../img/commercial/Com-5.jpg';
import Com6 from '../img/commercial/Com-6.jpg';
import Com7 from '../img/commercial/Com-7.jpg';
import Com8 from '../img/commercial/Com-8.jpg';

import '../App.css';

function ComImgTile() {
  return (
    <div style={{overflow:"hidden"}}>
        <Row style={{paddingTop:"20px", paddingBottom:"20px"}}>
            <Col lg="6">
                <Row>
                    <Col lg="6">
                        <div style={{width:"100%", height:"auto", overflow:"hidden"}}>
                            <img src={Com3} style={{width:"100%"}} className="imgHover mobMB" />
                        </div>
                    </Col>
                    <Col lg="6">
                        <div style={{width:"100%", height:"auto", overflow:"hidden"}}>
                            <img src={Com5} style={{width:"100%"}} className="imgHover" />
                        </div>
                        <div style={{width:"100%", height:"auto", overflow:"hidden", marginTop:"20px"}}>
                            <img src={Com6} style={{width:"100%"}} className="imgHover" />
                        </div>
                    </Col>
                    <Col lg="12">
                        <Row>
                            <Col lg="6">
                                <div style={{width:"100%", height:"auto", overflow:"hidden", marginTop:"20px"}}>
                                    <img src={Com8} style={{width:"100%"}} className="imgHover" />
                                </div>
                            </Col>
                            <Col lg="6">        
                                <div style={{width:"100%", height:"auto", overflow:"hidden", marginTop:"20px"}}>
                                    <img src={Com7} style={{width:"100%"}} className="imgHover mobMB" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row>
                    <Col lg="12">
                        <div style={{width:"100%", height:"auto", overflow:"hidden"}}>
                            <img src={Com4} style={{width:"100%"}} className="imgHover" />
                        </div>
                        
                    </Col>
                    <Col lg="6">
                        <div style={{width:"100%", height:"auto", overflow:"hidden", marginTop:"20px"}}>
                            <img src={Com1} style={{width:"100%"}} className="imgHover" />
                        </div>
                    </Col>
                    <Col lg="6">
                        <div style={{width:"100%", height:"auto", overflow:"hidden", marginTop:"20px"}}>
                            <img src={Com2} style={{width:"100%"}} className="imgHover" />
                        </div>
                    </Col>
                </Row>
            </Col>
            
        </Row>
        
    </div>
  );
}

export default ComImgTile;