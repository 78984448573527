import React from "react";
import '../App.css';

function NoPage() {
  return (
    <div className="vh-100"  fluid>
      <h1>Sorry. The page you are looking for does not exist. Please try another</h1>
    </div>
  );
}

export default NoPage;