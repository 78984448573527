import { BrowserRouter as Router, Link, Outlet, } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../img/phoenix-logo-sort.png';

export const Navigation = () => {
  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary navigationContainer" fixed="top" collapseOnSelect>
          <Navbar.Brand eventKey="1" as={Link} to="/">
              <img src={logo}  className='logo' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Link eventKey="2" as={Link} to="/fiktion">FIKTION</Nav.Link>
              <Nav.Link eventKey="3" as={Link} to="/reklamefilm">REKLAMEFILM</Nav.Link>
              <Nav.Link eventKey="4" as={Link} to="/om-phoenix">OM PHOENIX FILM</Nav.Link>
              <Nav.Link eventKey="5" as={Link} to="/kontakt">KONTAKT</Nav.Link>            
            </Nav>
          </Navbar.Collapse>
      </Navbar>
      <Outlet />
    </div>
  );
};
