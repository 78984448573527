import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../img/phoenix-logo-hvid.png';
import '../App.css';

function Footer() {
  return (
    <div className='footer clearfix'>
        <Row className='justify-content-center'>
          <img className='mb-4 footerLogo' src={logo} />
        </Row>
        <Container>
          <Row>
            <hr style={{color:"white"}} />
            <Col lg={6} style={{textAlign:"left", fontSize:"15px", color:"#FFF"}}>
                <span style={{color:"white"}}>&#169;</span><span style={{color:"white"}}> Phoenix Film Productions</span>
            </Col>
          </Row>
        </Container>
    </div>
  );
}
export default Footer;