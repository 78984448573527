import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Mov1 from '../img/production/Mov-1.jpg';
import Mov2 from '../img/production/Mov-2.jpg';
import Mov3 from '../img/production/Mov-3.jpg';
import Mov4 from '../img/production/Mov-4.jpg';
import Mov5 from '../img/production/Mov-5.jpg';
import Mov6 from '../img/production/Mov-6.jpg';
import Mov7 from '../img/production/Mov-7.jpg';
import Mov8 from '../img/production/Mov-8.jpg';
import Mov9 from '../img/production/Mov-9.jpg';
import '../App.css';

function MovieImgTile() {
  return (
    <div style={{overflow:"hidden"}}>
        <Row style={{paddingTop:"20px", paddingBottom:"20px"}}>
            <Col lg="6">
                <Row>
                    <Col lg="6">
                        <div style={{width:"100%", height:"auto", overflow:"hidden"}}>
                            <img src={Mov1} style={{width:"100%"}} className="imgHover mobMB" />
                           
                        </div>
                    </Col>
                    <Col lg="6">
                        <div style={{width:"100%", height:"auto", overflow:"hidden"}}>
                            <img src={Mov3} style={{width:"100%"}} className="imgHover" />
                        </div>
                        <div style={{width:"100%", height:"auto", overflow:"hidden", marginTop:"20px"}}>
                            <img src={Mov4} style={{width:"100%"}} className="imgHover" />
                        </div>
                    </Col>
                    <Col lg="12">
                        <Row>
                            <Col lg="6">
                                <div style={{width:"100%", height:"auto", overflow:"hidden", marginTop:"20px"}}>
                                    <img src={Mov5} style={{width:"100%"}} className="imgHover" />
                                </div>
                            </Col>
                            <Col lg="6">        
                                <div style={{width:"100%", height:"auto", overflow:"hidden", marginTop:"20px"}}>
                                    <img src={Mov7} style={{width:"100%"}} className="imgHover mobMB" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row>
                    <Col lg="12">
                        <div style={{width:"100%", height:"auto", overflow:"hidden"}}>
                            <img src={Mov9} style={{width:"100%"}} className="imgHover" />
                        </div>
                        
                    </Col>
                    <Col lg="6">
                        <div style={{width:"100%", height:"auto", overflow:"hidden", marginTop:"20px"}}>
                            <img src={Mov2} style={{width:"100%"}} className="imgHover" />
                        </div>
                    </Col>
                    <Col lg="6">
                        <div style={{width:"100%", height:"auto", overflow:"hidden", marginTop:"20px"}}>
                            <img src={Mov8} style={{width:"100%"}} className="imgHover" />
                        </div>
                        <div style={{width:"100%", height:"auto", overflow:"hidden", marginTop:"20px"}}>
                            <img src={Mov6} style={{width:"100%"}} className="imgHover" />
                        </div>
                    </Col>
                </Row>
            </Col>
            
        </Row>
        
    </div>
  );
}

export default MovieImgTile;