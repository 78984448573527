import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../App.css';

function Contact() {
  return (
    <div fluid>
      <div className="navBlocker"></div>
      <div className="contactSection">
        <Container>
          <Row className="justify-content-center">
            <Col sm="12" md="4" lg="4">
              <h1 className="aboutTopTitle" style={{paddingTop:"", textAlign:"center", color:"black"}}>KONTAKT</h1>
              <div className="contactTextSection">
              <h2><b>Phoenix Film Productions</b></h2>
                <p>Slagtehusgade 20<br />
                  DK 1711 København V<br />
                  <hr />
                  <b>Telefonnummer: </b>+45 23 66 05 17 <br />
                  <b>E-mail: </b>kontakt@phoenix-film.com</p>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Contact;